import searchForm from "../components/searchForm";
import topsSlider from "../components/sliders/topsSlider";
import topsBenefitsSlider from "../components/sliders/topsBenefitsSlider";
import excursionsSlider from "../components/sliders/excursionsSlider";
import banksSlider from "../components/sliders/banksSlider";

document.addEventListener('DOMContentLoaded', _ => {
    topsSlider();
    topsBenefitsSlider();
    excursionsSlider();
    banksSlider();
    searchForm.init();
});
