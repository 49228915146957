import Swiper, {
    Navigation,
    Scrollbar,
    Mousewheel,
    Virtual
} from 'swiper';

import 'swiper/swiper.min.css';

async function topsBenefitsSlider() {
    const req = await fetch('/api/property/profitable');
    const slides = await req.json();
    const slider = new Swiper('.tops_benefits__slider', {
        modules: [Navigation, Scrollbar, Mousewheel, Virtual],
        slidesPerView: 3,
        speed: 700,
        spaceBetween: 28,
        watchOverflow: true,
        mousewheelControl: true,
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            nextEl: '.tops_benefits__arrow--next',
            prevEl: '.tops_benefits__arrow--prev',
        },
        scrollbar: {
            el: '.tops_benefits__scroll',
            draggable: true,
        },
        breakpoints: {
            300: {
                slidesPerView: 1.1,
                spaceBetween: 20
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1124: {
                slidesPerView: 3,
                spaceBetween: 28
            },
        },
        virtual: {
            cache: false,
            slides,
            addSlidesAfter: 2,
            addSlidesBefore: 2,
            renderSlide: function (item) {
                return /*html*/`
                <a class="tops_card swiper-slide" href="/catalog/new/${item.slug}">
                    <div class="tops_card__bg">
                        <img
                            class="image"
                            data-lazy="data-lazy"
                            data-src="${item.cover}"
                            alt="${item.name}"
                        />
                    </div>
                    <div class="tops_card__tags">

                        <div class="tops_card__tag">
                            <img
                                class="icon"
                                data-src="/themes/aflat/assets/img/icon_fire.svg"
                                alt=""
                                data-lazy=""
                            />
                            <span class="text">Цена ниже рыночной</span>
                        </div>
                    </div>
                    <div class="tops_card__info">
                        <p class="tops_card__caption">${item.district.name}</p>
                        <h3 class="tops_card__title">${item.name}</h3>
                        <p class="tops_card__price">${item.priceString}</p>
                        <button class="tops_card__btn btn btn--white btn--small">
                            <span class="btn__text">${item.flatsString}</span>
                        </button>
                    </div>
                </a>

                `;
            }
        },
        on: {
            afterInit: _ => {
                document.dispatchEvent(new CustomEvent('ll::needUpdate'));
            },
            slideChangeTransitionEnd: _ => {
                document.dispatchEvent(new CustomEvent('ll::needUpdate'));
            }
        }
    })




}

export default topsBenefitsSlider;
