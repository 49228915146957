import Swiper, {
    Navigation,
    Scrollbar,
    Mousewheel,
    Virtual
} from 'swiper';

import 'swiper/swiper.min.css';
// import 'swiper/components/scrollbar/scrollbar.min.css';

const offersSlide = /*html*/ `
        <div class="tops__first_slide swiper-slide">
            <div class="tops__first_slide_bg">
                <picture class="image">
                    <source
                        data-lazy="data-lazy"
                        data-srcset="/themes/aflat/assets/img/tops_preview_pic.webp"
                        type="image/webp"
                    />
                    <source
                        data-lazy="data-lazy"
                        data-srcset="/themes/aflat/assets/img/tops_preview_pic.png"
                        type="image/png"
                    />
                    <img
                        class="image"
                        data-lazy="data-lazy"
                        data-src="/themes/aflat/assets/img/tops_preview_pic.png"
                        alt=""
                    />
                </picture>
            </div>
            <div class="tops__first_slide_content">
                <h3 class="tops__first_slide_title">
                    Лучшие <br class="desktop" />
                    предложения и ТОП жилых комплексов
                </h3>
                <p class="tops__first_slide_text">
                    Скачивайте подборки лучших предложений недвижимости
                    <br class="desktop" />
                    вашего города в одном файле. Обновляется ежемесячно.
                </p>
                <button
                    class="tops__first_slide_btn btn btn--filled btn--small"
                    data-linked-modal="modal_offers"
                >
                    <span class="btn__text"
                        >Получить лучшие предложения</span
                    >
                </button>
            </div>
        </div>
`;
async function topsSlider() {
    const req = await fetch('/api/property/top');
    const slides = await req.json();

    const slider = new Swiper('.tops__slider', {
        modules: [Navigation, Scrollbar, Mousewheel, Virtual],
        slidesPerView: 3,
        speed: 700,
        spaceBetween: 28,
        watchOverflow: true,
        mousewheelControl: true,
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            nextEl: '.tops__arrow--next',
            prevEl: '.tops__arrow--prev',
        },
        scrollbar: {
            el: '.tops__scroll',
            draggable: true,
        },
        breakpoints: {
            300: {
                slidesPerView: 1.1,
                spaceBetween: 20
            },
            500: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            1124: {
                slidesPerView: 3,
                spaceBetween: 28
            },
        },
        virtual: {
            cache: false,
            slides,
            addSlidesAfter: 2,
            addSlidesBefore: 2,
            renderSlide: function (item) {
                return item.has_best_offers ? offersSlide :
                /*html*/`
                <a class="tops_card swiper-slide" href="/catalog/new/${item.slug}">
                    <div class="tops_card__bg">
                        <img
                            class="image"
                            data-lazy="data-lazy"
                            data-src="${item.cover}"
                            alt="${item.name}"
                        />
                    </div>
                    <div class="tops_card__tags">
                        ${item.tags.reduce((html, tag) => (
                    html += /*html*/`
                                <div class="tops_card__tag"><span class="text">${tag.name}</span></div>
                            `
                ), '')}
                    </div>
                    <div class="tops_card__info">
                        <p class="tops_card__caption">${item.address}</p>
                        <h3 class="tops_card__title">${item.name}</h3>
                        <p class="tops_card__price">${item.priceString}</p>
                        <button class="tops_card__btn btn btn--white btn--small">
                            <span class="btn__text">${item.flatsString}</span>
                        </button>
                    </div>
                </a>

                `;
            }

        },
        on: {
            afterInit: _ => {
                document.dispatchEvent(new CustomEvent('ll::needUpdate'));
            },
            slideChangeTransitionEnd: _ => {
                document.dispatchEvent(new CustomEvent('ll::needUpdate'));
            }
        }
    });

}

export default topsSlider;
