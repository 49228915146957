import Swiper, {
    Navigation,
    Scrollbar,
    Mousewheel,
    Virtual
} from 'swiper';

import 'swiper/swiper.min.css';
// import 'swiper/components/scrollbar/scrollbar.min.css';

async function banksSlider() {

    const req = await fetch('/api/banks');
    const slides = await req.json();



    const slider = new Swiper('.banks__slider', {
        modules: [Navigation, Scrollbar, Mousewheel, Virtual],
        slidesPerView: 4,
        speed: 700,
        spaceBetween: 28,
        watchOverflow: true,
        mousewheelControl: true,
        virtual: {
            cache: false,
            slides,
            addSlidesAfter: 2,
            addSlidesBefore: 2,
            renderSlide: function (slide) {
                return /*html*/`
                    <div class="banks_card swiper-slide">
                        <div class="banks_card__top">
                            <div class="banks_card__img">
                                    <img
                                        class="image"
                                        src="${slide.logo_file?.path}"
                                        alt="${slide.name}"
                                    />

                            </div>
                            <h3 class="banks_card__title">${slide.name}</h3>
                        </div>
                        <div class="banks_card__info">
                        ${slide.rates.reduce((html, rate) => {
                            return html += /*html*/`
                                <div class="banks_card__line">
                                    <p class="banks_card__text">${rate.mortgage}</p>
                                    <p
                                        class="banks_card__text banks_card__text--percent"
                                    >
                                        ${rate.rate}
                                    </p>
                                </div>`
                            }, '')}
                        </div>
                        <button
                            class="banks_card__btn btn btn--filled btn--small"
                            data-linked-modal="modal_calc_mort"
                            data-modal-form-id="modal_calc_mort_banks"
                            data-bank-title="${slide.name}"
                        >
                            <span class="btn__text">Оставить заявку</span>
                        </button>
                    </div>
                `;
                }

        },
        mousewheel: {
            forceToAxis: true,
        },
        navigation: {
            nextEl: '.banks__arrow--next',
            prevEl: '.banks__arrow--prev',
        },
        scrollbar: {
            el: '.banks__scroll',
            draggable: true,
        },
        breakpoints: {
            300: {
                slidesPerView: 1.1,
                spaceBetween: 20
            },
            500: {
                slidesPerView: 3,
                spaceBetween: 20
            },
            1120: {
                slidesPerView: 4,
                spaceBetween: 28
            },
        },
        on: {
            afterInit: _ => {
                document.dispatchEvent(new CustomEvent('ll::needUpdate'));
            }
        },
        slideChangeTransitionEnd: _ => {
            document.dispatchEvent(new CustomEvent('ll::needUpdate'));
        }
    });

}

export default banksSlider;
